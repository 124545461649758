export const Apartments = [
  {
    "id": 0,
    "rent":3795,
    "bedrooms": 3,
    "rooms": 3,
    "bathrooms": 1.5,
    "fee": false,
    "borough": "Brooklyn",
    "neighborhood": "Boerum Hill",
    "address": "140 Fourth Avenue #1R",
    "images": [
      "https://photos.zillowstatic.com/fp/c7405aefca4113f09cb6a8f74ce3dfb6-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/c6cf5895f310e61382c5fa8074d15d2a-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/0dce2bfd0898bc472ce0701316735572-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/e0529b3d5bbcf00445effdb1d223a479-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/a35bcac87e15f83639bbe1ae187cfa06-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/48851a1e1e657d15af6620a7477f8376-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/9d37e4246d80e653fc763470c45cbe24-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/7af858121ca3ba93b8fd0dfd35b49c79-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/aced123059e1c58540f991d96e0ea122-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/b68280b314d89ed9a4aebb7fb19228ab-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/7e5f52bb3a8f87f85cc3d7b3bd2f6735-se_large_800_400.webp"
    ]
  },
  {
    "id": 1,
    "rent":3400,
    "bedrooms": 3,
    "rooms": 1,
    "bathrooms": 1,
    "fee": false,
    "borough": "Brooklyn",
    "neighborhood": "Clinton Hill",
    "address": "555 Waverly Avenue #6G",
    "images": [
      "https://photos.zillowstatic.com/fp/b016ee8ceb6bd77869ade1726b835edb-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/e0bbbfb627ff8d2fbaa6389ca465b80a-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/a3ddc0b9c2e7752ae703c9126e2c8f35-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/ddc61587c5e48745575450f7151e1c51-se_large_800_400.webp"
    ]
  },
  {
    "id": 2,
    "rent":2800,
    "bedrooms": 1,
    "rooms": 4,
    "bathrooms": 1,
    "fee": true,
    "borough": "Brooklyn",
    "neighborhood": "Carol Gardens",
    "address": "219 Carroll Street TOP-FLOOR",
    "images": [
      "https://photos.zillowstatic.com/fp/a8c13418dc15cb3f8d0b1929fa33db35-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/cf4ccefa9f3e79237b3a6f9cf04e4254-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/20d94ffe612d132975eb11c6e2d5d71a-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/f013fd61b76685e369c82cd8ad2020c5-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/b7eeb29c9b7ed9f9430fee721a46d190-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/edd2e760eb4d0ad81a7373efe87176f7-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/dcd99671263d78f64ac5db0545a9e9f8-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/68ef9a84fc5970bca283c7a1c63a7d36-se_large_800_400.webp"
    ]
  },
  {
    "id": 3,
    "rent":6300,
    "bedrooms": 5,
    "rooms": 12,
    "bathrooms": 3,
    "fee": false,
    "borough": "Brooklyn",
    "neighborhood": "Williamsburg",
    "address": "16 Meserole Street #PH",
    "images": [
      "https://photos.zillowstatic.com/fp/f44cebb3a219bcd90a232152a316ac76-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/daa807bf2953cb1e2ca1f65cfbaa2e8f-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/fd1b96d4800c1f2532f32efe47155c98-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/2f14a15e1835010cbdc4e8c7e0b0656b-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/e00122b3f18de370488667e9d256922d-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/1da24ad3e113f532579f23d115237303-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/025b933987dd7a4943dbca22c3055d89-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/0c1e342f6649d2c069dd18d732da68ed-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/ba91d741b4eb2f1e222ec996ac20ff71-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/71138343ffd71988699647691b11514c-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/03329b2595593c1f0032c30c9cbc89e6-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/cba237222879b93cb2f05ebae7da2ab4-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/d228dc9a491f9676c0e0a0d11b927c0c-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/53e8dd0c01e45a62f2cf3399a5cc2089-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/fde7e2f1469c52f38771eb0d9c8b55f9-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/4f391338274ec62a506c682ad1d9ba54-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/cfd82297bc4e79fedf8159dcd00b9e8e-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/4dfaadb8fa93b49f7fd272a0eee30bd8-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/3c56551e6cb497dfaee1730bf813dee1-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/70ad4d1e0a5b411b447c60677e102bd0-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/894cc2ad9e70799c04d386053f74ec6b-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/b430be09b726c67ea52cac9dcad42c16-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/7e174f08c11a994971e8603bce3a7b3e-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/75f78a75e4cf469ed808f5f823734362-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/c2b884be2e77dd02f0b1d5ce12a00c72-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/e2f7fe23b75b2c8956beed200d38306c-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/e72029d8ef39e7e06a5060c0593cae12-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/fac96f11d5f9aa687ef45a3b76383e4b-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/dc7a0d805f3f2de16c534645a55b62fa-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/bf3f405f41ac1fb1b613ee7a247a2213-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/c3f3eaf3d4ae11bf33a71e66c03cc3f9-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/c4d690ca09837dfd15fb550bf5b561a9-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/1102ab9f6915276ea324ace17db45b19-se_large_800_400.webp"
    ]
  },
  {
    "id": 4,
    "rent":2100,
    "bedrooms": 2,
    "rooms": 3,
    "bathrooms": 1,
    "fee": false,
    "borough": "Brooklyn",
    "neighborhood": "Farragut",
    "address": "4022 Glenwood Road #1",
    "images": [
      "https://photos.zillowstatic.com/fp/6cefd666d7e0c3c1edb4d46b183d04db-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/9dabb6fc350bb5838e00e75f1ee13683-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/71a1872020d760f46bfbe002605a2566-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/dbc267d76885c6fcfc2e993917f7148b-se_extra_large_1500_800.webp",
      "https://s3.amazonaws.com/img.streeteasy.com/nyc/attachments/28280470/large/3b8d2a3e11e447c3cc589bc53439e2815d1c2794.jpg"
    ]
  },
  {
    "id": 5,
    "rent":1600,
    "bedrooms": 0,
    "rooms": 1,
    "bathrooms": 1,
    "fee": false,
    "borough": "Manhattan",
    "neighborhood": "Hudson Heights",
    "address": "225 Bennett Avenue #2C",
    "images": [
      "https://photos.zillowstatic.com/fp/c3be7e380ceabcef2c8fa0c3f6c05915-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/927c3e032d9d789fd17c1c85f4d46ce9-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/da86e686956b93625dc819901aefbdf7-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e208a97898f63dee447723dd3a2b3243-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f19c6ce97c99d41080e73033cdb74e20-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 6,
    "rent":4149,
    "bedrooms": 3,
    "rooms": 3,
    "bathrooms": 2,
    "fee": false,
    "borough": "Manhattan",
    "neighborhood": "South Harlem",
    "address": "191 Saint Nicholas Avenue #3HN",
    "images": [
      "https://photos.zillowstatic.com/fp/d0a1e8aaff66662c1b01689fde6e999e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/b9fee8d2948d108703508f53badb1dce-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/eb509a63b9edff84cfe0026025a28205-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/35bfb08b4aff4bce3286de793f219692-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/c163e09c74eed769e89ac3c360e193ee-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/14cb3aadf0339ad35322693a2f61067a-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/6cce208bedaa8326121f3cf850b1b8fa-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/b56da5cf852abffd768d2dc256aaf22d-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 7,
    "rent":4500,
    "bedrooms": 1,
    "rooms": 3,
    "bathrooms": 1,
    "fee": true,
    "borough": "Manhattan",
    "neighborhood": "Turtle Bay",
    "address": "145 East 48th Street #16G",
    "images": [
      "https://photos.zillowstatic.com/fp/8349205d822cc130337ba591f8f8be6c-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/19902d81be8feef2f7536bfd087c2a39-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1cf65e8d3d488d5fb453ae67e4ba8b9f-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/d2c638f7bec170ed3912c302bd7cce02-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/2ab43a51cea075d1c7f172dde3daf94e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/815fbf135d97517cf9906b31861c8ef4-se_extra_large_1500_800.webp",
      "https://s3.amazonaws.com/img.streeteasy.com/nyc/attachments/28140041/large/5b35ac992660d3ce88c86e57ba2f05be101ed32c.jpg"
    ]
  },
  {
    "id": 8,
    "rent":6000,
    "bedrooms": 2,
    "rooms": 4,
    "bathrooms": 2,
    "fee": true,
    "borough": "Manhattan",
    "neighborhood": "Lower East Side",
    "address": "177 Hester Street #6",
    "images": [
      "https://photos.zillowstatic.com/fp/f0b12acf1757fe7930be084449e9265e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e9ba00f670cc8645ea2795448992b640-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/672479d5bfb3acf35edb4ce27d5e1b09-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/6ee0e14fb62ab6652cab386bc28288c1-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f084c07697aef15e963b9ca37e2ad01b-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/c0a2ac10b7a93b3764a45cba82aca224-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/8547f39dc2ee974c5defab001e5391f0-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 9,
    "rent":2195,
    "bedrooms": 0,
    "rooms": 2,
    "bathrooms": 1,
    "fee": true,
    "borough": "Manhattan",
    "neighborhood": "Lenox Hill",
    "address": "207 East 76th Street #1A",
    "images": [
      "https://photos.zillowstatic.com/fp/579d993436b3dc5806be96db9b44a921-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/7d002b9b835b432c2bbe7a3c9742d66a-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/6648c64a74882b95a428bb2e75070b47-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1a55dcbede44285381c0b8ff4bb090b5-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/cfca34bbbffbbf4c32bd960df1873e4a-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/24013fcd2ffbb72753310890f7f54a22-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 10,
    "rent":3850,
    "bedrooms": 1,
    "rooms": 5,
    "bathrooms": 1,
    "fee": true,
    "borough": "Manhattan",
    "neighborhood": "Hudson Yards",
    "address": "455 West 37th Street #PH6",
    "images": [
      "https://photos.zillowstatic.com/fp/4b94e6c525b5bd0e91a3125db42f5344-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/00f1cdc5537215af58bc0101ec130b3d-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/ba81ae6ea52e0618a1af385eb75ccfb1-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/741237748448abbe67bb137c733fda43-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/d253db66009adbed16c65da0d53d7edc-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4fbd2928efab92cabb3a0f172d1b0970-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 11,
    "rent":2200,
    "bedrooms": 3,
    "rooms": 4,
    "bathrooms": 1,
    "fee": true,
    "borough": "Bronx",
    "neighborhood": "Wakefield",
    "address": "4459 Richardson Avenue #BSMT",
    "images": [
      "https://photos.zillowstatic.com/fp/4a9466b3a143a7f245613e406cc16288-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/3e45b5c809519f1fcab2c64382fd5cf7-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/6810cc2b626e73c150bef4c451e95f27-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/292bea045dba96db30afb9bed8ed3c94-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/ba85a486adcec9fbe75860451d88dbcf-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/57e81ee1806a1c64aff876a5e74c8a5b-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 12,
    "rent":2000,
    "bedrooms": 2,
    "rooms": 4,
    "bathrooms": 1,
    "fee": true,
    "borough": "Bronx",
    "neighborhood": "Woodstock",
    "address": "652 Beck Street #2",
    "images": [
      "https://photos.zillowstatic.com/fp/a71644de3046c951c4ac757e2c7ca799-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/2700aaa70841ede57927f3d328cb92e0-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/a4d8b0a9edddeb5b84482ebedf69f3d7-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/fe47df16d224e8d3e2ad19f45c6521ba-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/eaa69b28caab544f2abf673b2e4dde61-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/6f90aa797cace20ed8cd82d7993b9458-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/b47e913fc4f9f672e77b12a49040a3ce-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/646bd2b50ce32954c2b3eac64d8c2314-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/8afe399c1c04c574de7a5fc67f792b56-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/bf975323a52d9818f0858680414d7edd-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 13,
    "rent":3375,
    "bedrooms": 2,
    "rooms": 3,
    "bathrooms": 1,
    "fee": false,
    "borough": "Bronx",
    "neighborhood": "Mott Haven",
    "address": "224 East 135th Street #2306",
    "images": [
      "https://photos.zillowstatic.com/fp/a583e61b778b9deec9fe650817a27d84-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/25ba8bac278abdcd572a815bacb91adf-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/d89517246458245f2be7fb673114d4e2-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f19ef54eba76ab6d65b3e1240ec98579-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/baf7f6174ea8d3211de5d7c0c4a52d13-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/a9eebe7feffabbb4e5c190f016bf3fec-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1e5cef7bb66b55fc801921a36a7083ca-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/9b277be8a0fc1ae6b90d677d409b3c05-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/68e4069ce07038dc22c2f5f1f23e08c6-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/6f47318ff699a75b5006b2daa05a34da-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/50b625863418940cc55c17d11fa22c08-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/955743cd771872d04dd135d906feca77-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/5598554e27b38ef96c00bfba3a71eeba-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/0f4275db7ed4cf482055408890133616-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/7121ec5b43f2c5fe10cd754e99c1ffd6-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/151f75990c79123a10c84f96374de424-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/b906e74e10101e6e0a8e4edf701c571d-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/ea641b736448028c0b5eb325ccb83e02-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 14,
    "rent":1545,
    "bedrooms": 0,
    "rooms": 2,
    "bathrooms": 1,
    "fee": true,
    "borough": "Bronx",
    "neighborhood": "Kingsbridge",
    "address": "3120 Corlear Avenue #506",
    "images": [
      "https://photos.zillowstatic.com/fp/f28809636a9b430060a5e60817b0631f-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/03fd3c4677c88e259f36a31ff174559b-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/243388f892e646c92367971f646f1843-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/a9adcffdd643feaafa6b39b117b3a510-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/26817f06cf9fa9d98339b3174aa1a610-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/db8eb5f5b394711c8eb50bed5adc750c-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/c84da74cf579aa462b08b36e6bcfe82c-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1efc34de98832f98ab6bdb7e0f42dce0-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/57ac92018f206adad35802cae8fc514f-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/c7a6bbfd14ea53117a0a6bdbcc2d7f20-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/5c81e5fd829a832a1f698e70ccdda304-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/9774c2956c46091953d56c8f41868429-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1b9f06275a185a91410d9c610b3a522a-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/eeed27cc5ad077ed820730c43ab374ec-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4ed3d20c74d35686d18eec32c3b7fbcf-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1117d758994d05dbe34d95927d70afdc-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/21a4920d48010873f1f9732b2285bae1-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 15,
    "rent":2299,
    "bedrooms": 3,
    "rooms": 5,
    "bathrooms": 1,
    "fee": true,
    "borough": "Bronx",
    "neighborhood": "Highbridge",
    "address": "155 West 162nd Street #2A",
    "images": [
      "https://photos.zillowstatic.com/fp/d3a4a7039c39d5d351f8f75142067f8d-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/914c493844e5a3dd384bb814f342b946-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4fdaf860c0d58d485f2c97527359ae1b-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/098bbc8b415e5b88d0ea7e476a90465e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/8d86de7845bfc5b075e16f402a26b4b6-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f71bb5972aa3cc073f7570d81dadcf70-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 16,
    "rent":4000,
    "bedrooms": 4,
    "rooms": 8,
    "bathrooms": 2,
    "fee": true,
    "borough": "Queens",
    "neighborhood": "Astoria",
    "address": "30-86 36th Street #1R",
    "images": [
      "https://photos.zillowstatic.com/fp/85682332a0a1995f29650cc9dbde9257-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/a3cb003de152205c9c145862ffd20c3f-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/559968be244403fb43041db2c1766687-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/7d6314edf213be8f4656e36eeba69439-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e455a23ca8e4af5e58303250055d7fe6-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/eed13a5b51288e2f3b382e1231467317-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/829105dd3a9a7e83d979a89cdb1a3f72-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e4e282331decd8da1b6d49abb068e68c-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4b989bf3512a78c4e1b435b0aa60fbb7-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/86852152b80d869da2fa0fd3d11dd707-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/08363749465db0f65c881169bd3caaef-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 17,
    "rent":2400,
    "bedrooms": 2,
    "rooms": 6,
    "bathrooms": 2,
    "fee": true,
    "borough": "Queens",
    "neighborhood": "Oakland Gardens",
    "address": "67-11 210th Street",
    "images": [
      "https://photos.zillowstatic.com/fp/70cb649f6391e90c5e2095c92887fb6c-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/7aab9b274e90738d6ce39f7108f9b56e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f5bbca5215c15fc9c9001850239b7db9-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/9ac68bac0aef437cae24cfa5644ffdbf-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4253947dcfc35150f13083fd770cd94f-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/764825ff07e6bfa99c85e4f4a5111ebe-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f22646e08b5244084532a190a35aa206-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/803bc29edb53d90a7c2024b9d393657e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/65e7a9d27433b73bcedcae31a6855495-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/7e33b8eb0457ed478f212175eddca6f4-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/0ac7b9aa1266a8b839fea0e7b01ba186-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/8a12adadff657e9b8460356375cc3413-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/df5a59e495f9f7053d95732b43e40cf2-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/0fa1f3c788b7d4be6ecaec33148fe7a6-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/098f8d152f4138fd5ad2b6fe78cab6d8-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f77cb8fe6a5329a656ad3e9940b4fb96-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/db171d7d09510523fffe5046714cc02c-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/90e247a17713950466f1be730f53ddf4-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/7bc060989371e9e7b7155817ddfeaf7e-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id":18,
    "rent":3300,
    "bedrooms": 4,
    "rooms": 6,
    "bathrooms": 2,
    "fee": false,
    "borough": "Queens",
    "neighborhood": "Maspeth",
    "address": "72-02 44th Avenue #3",
    "images": [
      "https://photos.zillowstatic.com/fp/6564966b12c5cccb93e54367204e343a-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/b48022827e7321f3b9b29513068c9ae6-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f8716738300a346e1afb3922c6f7664d-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1aac7bc4617fa3fb248f7bf1233d6819-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e9147a97a36bfb822f249283dfb0bd2a-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 19,
    "rent":1895,
    "bedrooms": 1,
    "rooms": 1,
    "bathrooms": 1,
    "fee": true,
    "borough": "Queens",
    "neighborhood": "Middle Village",
    "address": "63-34 Woodhaven Boulevard #2R",
    "images": [
      "https://photos.zillowstatic.com/fp/9a3d3b7fbf4b6aca00edb5cbb3309572-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/ccb80b61f63e7571bddf385304444ba8-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/cf463ac77f6eaa0324675e1c090752c4-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/d12be5eb8911572a1c3b74425e3048a7-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/b3435363f7e479c9f4cde2167eb4bfda-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/be62ce6256d15f5146a89ec1fc5892df-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e088ed4c316b5350aca30cd4361b88d3-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/417414c4bab257577da3463540f62a53-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/952f065ef60fc30cd36f2415a69c8d73-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 20,
    "rent":2635,
    "bedrooms": 1,
    "rooms": 3,
    "bathrooms": 1,
    "fee": false,
    "borough": "Queens",
    "neighborhood": "Astoria",
    "address": "34-46 Vernon Boulevard #E407",
    "images": [
      "https://photos.zillowstatic.com/fp/4a053fc111956b33399ec42912ab56d4-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/03264f4f6b20c8802100eeec3c8458c2-se_large_800_400.webp",
      "https://photos.zillowstatic.com/fp/70dde9072a2cd713ef08daed6367c33f-se_large_800_400.webp"
    ]
  },
  {
    "id": 21,
    "rent":2950,
    "bedrooms": 2,
    "rooms": 5,
    "bathrooms": 2,
    "fee": true,
    "borough": "Staten_Island",
    "neighborhood": "Saint George",
    "address": "153 Bay Street #6E",
    "images": [
      "https://photos.zillowstatic.com/fp/91a16bdbdc456074f686c0e66b25d71b-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/9310233492379fb084cdcbe7b23e3691-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/c8535398fcf58957ae8f02b8bb7e69bc-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/8fdd9e44eafa6bc40408a12017921c7a-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/01a44af52a9ac106b70bcdd2a7902816-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/b4c0c627a0947b433cd7d932d33788c0-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/27e21acee09bfc4a74fa8b35a63eed65-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e944c6b3bf11e587267fecd4b317aaa1-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/3ca03cdd96d65b6e275acdfeec78b1e3-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/1ad7354683881338aa82e533bb205196-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/8bb90958cc3ed92bf2958ea5578bcbde-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/719d904867ecb54a07ec76223d2ace1b-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/ff9aa8f8572c4c61b76834b9639d1c65-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/bc6ac4b538f31674dafe58201f372d90-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/78f1da353a47a991ce4c00f95d399b89-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4b292ae9f2e92a34a823d534ac3e4518-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/741564778d7017be73b946bce683b025-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 22,
    "rent":2650,
    "bedrooms": 3,
    "rooms": 3,
    "bathrooms": 1.5,
    "fee": false,
    "borough": "Staten_Island",
    "neighborhood": "Port Richmond",
    "address": "152 Egbert Avenue #2",
    "images": [
      "https://photos.zillowstatic.com/fp/aac9799332c06efbe15f145253c37687-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/3332391c1625dec26f0573b4558c6bf2-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/34659a5f2bcc765f2c63fbfc4e01ea39-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/42186d8fdf6e9cab8739e20e0000e59c-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/cc86418d030d5ca40490cfc98be79ac4-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/045287c3e48889177af6cf9ad49e4591-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/412674b98e738c1eb2862de1aea95642-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/2a30fe22a73b72e8a6c4e4625202e583-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 23,
    "rent":1500,
    "bedrooms": 1,
    "rooms": 4,
    "bathrooms": 1,
    "fee": true,
    "borough": "Staten_Island",
    "neighborhood": "Great Kills",
    "address": "101 Brookfield Avenue GROUND-FLOOR",
    "images": [
      "https://photos.zillowstatic.com/fp/2de2f9014df33022bae90e4dc9267992-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f8276a188d684bee344466cbc83bcba5-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/5387a0310fd5cc793ac34dcba314e7ab-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/03ed72f9d40fdb4ca85d27e49e9a16e9-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/a057db2f07887f099addffdd7a1d0f8d-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/5b242bfd79715601112c412044b62eba-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/e97c4a044ff9d95b73a52662b72a3af8-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/8193c301adb6ced5f7e756223db7778b-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 24,
    "rent":2800,
    "bedrooms": 3,
    "rooms": 8,
    "bathrooms": 3,
    "fee": true,
    "borough": "Staten_Island",
    "neighborhood": "Huguenot",
    "address": "91 Canton Avenue #2",
    "images": [
      "https://photos.zillowstatic.com/fp/01da7635589a8fd552b342717d6c2860-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/f446deae8eb8d127c1ebf57913fb3a05-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/0354c561135b7c64d0fbbf489d457a31-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4f33ca9917493831534ad61eabc7b0c2-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/2400b849797a0584b610245dd80647f9-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4608fd9d29371aedc9c929f23c7b6e14-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/80b657b094b6c1461caeba1bbdfb8550-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/2604c51b099a3aa701b2a4c538059645-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/2b700d7d0701c70cba8796ef7dca7df6-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/cefd4d4c9c71897de564edc092eb6008-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/ac1f822e696e62c26ec4b75528999a4e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/d0a7bf8bcf0bceec6f2c28ec53e94c3e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/ce1a60a8266080bba343a67727b2b64e-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/33c4f58b85c9223cc1159ef4a9ae77e7-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/aa115b0a444c54ffb0c68451617d0c35-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/bc4ab9d89a58e9e4770e1b97488b99f0-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/33b946b124a1a7c2acb21bed6a185d68-se_extra_large_1500_800.webp"
    ]
  },
  {
    "id": 25,
    "rent":1750,
    "bedrooms": 0,
    "rooms": 2,
    "bathrooms": 1,
    "fee": true,
    "borough": "Staten_Island",
    "neighborhood": "Bulls Head",
    "address": "146 Amsterdam Avenue WALK-IN",
    "images": [
      "https://photos.zillowstatic.com/fp/2584cb0549d6358e7d16eadd0b165b94-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/07b402016b4681852de79b8e31787093-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/d86875adb3d19e09db6a741414767518-se_extra_large_1500_800.webp",
      "https://photos.zillowstatic.com/fp/4244815385ff32e5a4e464abf10bdef5-se_extra_large_1500_800.webp"
    ]
  }
]
