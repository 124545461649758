import React from 'react';
import { Box, Flex, Heading, Text } from 'rebass';
import GameManager from './gameManager/gameManager';

export const App = () => {
  return (
    <>
      <Flex flexDirection={"row"} width={"fill"} margin={"-8px"}>
        <Box width={1}>
          <Heading padding={10} backgroundColor={"#d1daec"} className={"header"} fontFamily={"calibri"} fontSize={6}>I Can't Believe It's Not Brooklyn!</Heading>
          <Heading padding={10} backgroundColor={"#d1daec"} className={"header"} fontFamily={"calibri"} fontSize={4}>Guess the borough, time matters!</Heading>
        </Box>
      </Flex>
        <GameManager/>
    </>
  );
};

export default App;
