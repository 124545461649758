import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { createClient, Provider } from 'urql';
import App from './app/app';

const client = createClient({
  url: 'https://62895e9d2b80.ngrok.io/',
});

ReactDOM.render(
  <StrictMode>
    <Provider value={client}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
