import React, { useCallback, useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import ApartmentInfo from '../apartmentInfo/apartmentInfo';
import SvgMap from '../guessr/svgMap';
import useEventListener from '../guessr/useEventListener';
import { Apartments } from './ApartmentData';


interface Apartment {
  id: number,
  rent: number;
  bedrooms: number;
  rooms: number;
  bathrooms: number;
  neighborhood: string;
  fee: boolean;
  borough: string;
  address: string;
  images: string[];
}


const GameManager = () => {
  const [score, setScore] = useState(0);
  const [currentApartmentIndex, setCurrentApartmentIndex] = useState(0);
  const scoreIncrement = 500;
  const correctSoundEffect = new Audio('../assets/sound_effects/correct.wav');
  const incorrectSoundEffect = new Audio('../assets/sound_effects/incorrect.wav');
  const gameLength = 5;
  const [currentApartmentCount, setCurrentApartmentCount] = useState(1);
  let startTime = new Date();
  const newGame = () => {
    startTime = new Date();
    return () => {
      setScore(0);
      setCurrentApartmentIndex(Math.floor(Math.random() * apartments.length));
      setCurrentApartmentCount(1);
    };
  };



  const apartments: Apartment[] = Apartments;
  const showNewGameScreeen = apartments?.length > 5;

  const newGameKeybind = useCallback((evt: KeyboardEvent) => {
    evt.preventDefault()
    if (showNewGameScreeen && currentApartmentCount > gameLength && evt.key === " ") {
      newGame()()
    }
  }, [currentApartmentCount, newGame, showNewGameScreeen])
  useEventListener("keydown", newGameKeybind);



  const newGameScreen = (
    <div>
      <Flex marginX={-10} padding={10} backgroundColor={"#d1daec"} className={"score"} flexDirection={'row'} justifyContent={'space-between'} paddingX={'16px'} paddingY={'10px'}>
        <Text fontFamily={"calibri"} fontSize={5}>Game Over</Text>
        <Text fontFamily={"calibri"} fontSize={5}>Score: {score}</Text>
      </Flex>
      <Flex justifyContent={'center'}>
        <Button bg={'blue'} onClick={newGame()}>Play Again</Button>
      </Flex>
    </div>
  );

  const outOfApartmentsScreen = (
    <div>
      <Flex marginX={-10} padding={10} backgroundColor={"#d1daec"} className={"score"} flexDirection={'row'} justifyContent={'space-between'} paddingX={'16px'} paddingY={'10px'}>
        <Text fontFamily={"calibri"} fontSize={5}>Game Over</Text>
        <Text fontFamily={"calibri"} fontSize={5}>Score: {score}</Text>
      </Flex>
      <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
        <Text fontSize={5} paddingY={'16px'}>Thanks For playing!</Text>
        <Text fontSize={4}>You've guessed all the apartments for now, but check back again soon for more or reload the
          page to play again</Text>
      </Flex>
    </div>
  );
  const gameOverScreen = showNewGameScreeen ? newGameScreen : outOfApartmentsScreen;

  const onSelect = (selection: string) => {
    if (selection === (apartments[currentApartmentIndex].borough)) {
      correctSoundEffect.play();
      const seconds = (new Date().getTime() / 1000) - (startTime.getTime() / 1000);
      const increase = scoreIncrement - Math.floor(seconds / 5) * 50;
      increase < 100 ? setScore(score + 100) : setScore(score + increase);
    } else {
      incorrectSoundEffect.play();
    }
  };

  const nextApartment = () => {
    startTime = new Date();
    if (currentApartmentIndex > -1) {
      apartments.splice(currentApartmentIndex, 1);
      setCurrentApartmentCount(currentApartmentCount + 1);
    }
    setCurrentApartmentIndex(Math.floor(Math.random() * apartments.length));
    const tableBody = document.getElementById('images-box');
    if (tableBody) {
      tableBody.scrollTop = 0;
    }
  };

  const gameScreen = (
    <Box>
      <Flex  width={"fill"} margin={"-8px"} padding={10} backgroundColor={"#d1daec"} className={"score"} flexDirection={'row'} justifyContent={'space-between'} paddingX={'16px'} paddingY={'16px'}>
        <Text fontFamily={"calibri"} fontSize={5}>Round: {currentApartmentCount}/{gameLength}</Text>
        <Text fontFamily={"calibri"} fontSize={5}>Score: {score}</Text>
      </Flex>
      <Flex flexDirection={'row'}  width={"fill"} margin={"-8px"} justifyContent={'center'} alignItems={'top'} paddingX={'32px'}
            paddingY={'10px'}>
        <Box justifyContent={'center'} alignItems={'center'} width={.5}>
          <ApartmentInfo {...apartments[currentApartmentIndex]}/>
        </Box>
        <Box width={0.5} height={'auto'}>
          {apartments[currentApartmentIndex] &&
          <SvgMap onSelect={onSelect} onNext={nextApartment} answer={apartments[currentApartmentIndex].borough}/>}
        </Box>
      </Flex>
    </Box>
  );

  return (
    currentApartmentCount > gameLength ? gameOverScreen : gameScreen
  );
};

export default GameManager;
