import React, { useCallback, useState } from 'react';
import Question from '../../assets/maps/question';
import AnswerBronx from '../../assets/maps/answerBronx';
import './svgMap.css';
import AnswerManhattan from '../../assets/maps/answerManhattan';
import AnswerBrooklyn from '../../assets/maps/answerBrooklyn';
import AnswerStatenIsland from '../../assets/maps/answerStatenIsland';
import AnswerQueens from '../../assets/maps/answerQueens';
import useEventListener from './useEventListener';


const SvgMap = (props: { onNext: () => void, onSelect: (selection: string) => void, answer: string }) => {
  const [guessed, setGuessed] = useState(false);
  const { onNext, onSelect, answer } = props;
  const onClick = useCallback((borough: string) => {
    onSelect(borough);
    setGuessed(true);
  }, [onSelect]);
  const nextApartment = useCallback(() => {
    setGuessed(false);
    onNext();
  }, [onNext]);
  const nextKeybindHandler = useCallback((evt: KeyboardEvent) => {
    if (guessed && evt.key === " ") {
      evt.preventDefault()
      nextApartment()
    }
  }, [guessed, nextApartment])
  const guessKeybindHandler = useCallback((evt: KeyboardEvent) => {
    const keymap = {
      "a": "Brooklyn",
      "s": "Manhattan",
      "d": "Bronx",
      "f": "Queens",
      "g": "Staten_Island"
    }
    const keys = Object.keys(keymap);
    if (!guessed && keys.indexOf(evt.key) !== -1) {
      const key = evt.key as keyof typeof keymap;
      onClick(keymap[key])
    }

  }, [guessed, onClick])
  useEventListener("keydown", nextKeybindHandler);
  useEventListener("keydown", guessKeybindHandler);
  let answerDiv = <div/>;
  switch(answer) {
    case "Bronx":
      answerDiv = <AnswerBronx onNext={nextApartment}/>;
      break;
    case "Manhattan":
      answerDiv = <AnswerManhattan onNext={nextApartment}/>;
      break;
    case "Brooklyn":
      answerDiv = <AnswerBrooklyn onNext={nextApartment}/>;
      break;
    case "Staten_Island":
      answerDiv = <AnswerStatenIsland onNext={nextApartment}/>;
      break;
    case "Queens":
      answerDiv = <AnswerQueens onNext={nextApartment}/>;
      break;
  }
  return (
    guessed ? answerDiv : <Question onSelect={onClick}/>
  );
};

export default SvgMap;
