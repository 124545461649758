import React from 'react';
import { Box, Flex, Heading, Image, Text } from 'rebass';


const ApartmentInfo = (props: {id: number, rent: number, bedrooms: number, rooms: number, bathrooms: number, fee: boolean, borough: string, neighborhood: string, address:string, images: string[]}) => {

  return(
    <Flex flexDirection={"column"}>
      <Text fontFamily={"calibri"} fontSize={4}>
        Rent: ${props.rent}/month
      </Text>
      <Text fontFamily={"calibri"} fontSize={4}>
      Broker's Fee: {props.fee ? "Yes" : "No"}
      </Text>
      <Text fontFamily={"calibri"} fontSize={4}>
        Rooms: {props.rooms}
      </Text>
      <Text fontFamily={"calibri"} fontSize={4}>
        Bedrooms: {props.bedrooms}
      </Text>
      <Text fontFamily={"calibri"} fontSize={4}>
        Bathrooms: {props.bathrooms}
      </Text>
      <Box height={1000} overflowY={"scroll"} id="images-box">
      {props.images?.map(image => (
        <Image width={"100%"} src={image}/>
      ))}
      </Box>
    </Flex>
  )
}

export default ApartmentInfo
